/* Modal overlay */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
}

/* Modal content box */
.modal-content {
	background: #fff;
	border-radius: 8px;
	width: 100%;
	max-width: 500px;
	max-height: 80vh;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	position: relative;
	display: flex;
	flex-direction: column;
}

/* Modal header */
.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid #ddd;
}

.modal-header h4 {
	margin: 0;
}

/* Modal close button */
.close-button {
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
	color: #333;
}

.close-button:hover {
	color: #000;
}

/* Modal body */
.modal-body {
	padding: 1rem;
	overflow-y: scroll;
}

/* Modal footer */
.modal-footer {
	padding: 1rem;
	border-top: 1px solid #ddd;
	display: flex;
	justify-content: flex-end;
	gap: 0.5rem;
}
