@keyframes pulse {
	0% {
		background-color: #e9ecef;
	}
	50% {
		background-color: #f4f6f7;
	}
	100% {
		background-color: #e9ecef;
	}
}

.text-placeholder {
	animation: pulse 1.5s infinite;
}
