// Import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// Override Bootstrap variables here
// $primary: rgb(70, 210, 129); // Replace with desired color
$secondary: #ddd; // Replace with desired color
// $success: rgb(70, 220, 129);
// $danger: rgb(224, 62, 74);

// Override Bootstrap variables here
$primary: rgb(60, 220, 120); // Brighter green
// $secondary: rgb(50, 60, 90); // Richer dark gray-blue
$success: rgb(60, 220, 120); // Same as primary for consistency
$info: rgb(45, 170, 255); // More intense cyan-blue
$warning: rgb(255, 165, 50); // More saturated orange
$danger: rgb(245, 50, 90); // Richer, punchier red-pink
$dark: rgb(25, 30, 35); // Slightly deeper gray
$light: rgb(240, 243, 245); // Crisp, brighter light gray
$purple: rgb(145, 60, 235); // More vibrant and electric purple
$yellow: rgb(255, 193, 7); // More intense yellow

// $border-radius: 1.5rem; // Adjust border radius for all components
$font-size-base: 0.85rem; // Adjust base font size
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1600px,
);
$spacer: 1rem;

// Import Bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// Set Urbanist font for headings
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	color: #000000;
}

p,
td,
th,
span,
div {
	color: #666;
}

html,
body {
	background-color: rgb(248, 249, 250);
}

body {
	margin: 0;
	font-family:
		// 'Inter',
		-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

td {
	word-wrap: no-wrap;
}

.btn-primary,
.btn-primary:hover {
	color: white;
}

.btn-link {
	color: $dark;
	text-decoration: none;
}
